import React from 'react';
import './footer.css';
import { motion } from 'framer-motion';

let easing = [0.1575, 0.85, 0.42, 0.96];

const fadeIn = {
  enter: { x: 0, y: 0, opacity: 1, transition: { duration: 1, ease: easing } },
  exit: { x: 0, y: 0, opacity: 0, transition: { duration: 1, ease: easing } }
}

const Footer = () => {
  return (
    <motion.div initial="exit" animate="enter" exit="exit">
      <motion.footer variants={fadeIn}>Copyright © 2019-2022 Neobility SRL. All rights reserved.</motion.footer>
    </motion.div>
  )
}

export default Footer;