import React from 'react';
import './button.css';

const Button = ({type, icon, children, onClick, className}) => {
  return (
    <button className={`btn btn-${type} ${icon ? 'btn-icon' : ''} ${className}`} onClick={onClick}>
      {icon && <img className="icon" alt="Button icon" src={icon}/>}
      <div className="text">{children}</div>
    </button>
  )
}

export default Button;