import React from 'react';
import backgroundImg from '../images/bg.svg';
import { motion } from 'framer-motion';

let easing = [0.1575, 0.85, 0.42, 0.96];

const slideTopAnimation = {
  enter: { x: 0, y: 0, opacity: 1, transition: { duration: 1.6, ease: easing } },
  exit: { x: 0, y: 50, opacity: 0, transition: { duration: 1.6, ease: easing } }
}

const BackgroundMobile = () => {
  return (
    <motion.div initial="exit" animate="enter" exit="exit" className="fullscreen-svg-mob">
      <motion.img variants={slideTopAnimation} src={backgroundImg}/>
    </motion.div>
  )
}

export default BackgroundMobile;