import React from 'react';
import Button from '../Button';
import logoLandscape from '../../images/logo-landscape.svg';
import './indexContent.css';
import { motion } from 'framer-motion';
import neoDeliveryIcon from "../../images/neo-delivery-icon.svg";
import urbanAirIcon from "../../images/urban-air-icon.svg";

let easing = [0.1575, 0.85, 0.42, 0.96];

const fadeIn = {
  enter: { x: 0, y: 0, opacity: 1, transition: { duration: 1, ease: easing } },
  exit: { x: 0, y: 0, opacity: 0, transition: { duration: 1, ease: easing } }
}

const IndexContent = () => {
  return (
    <motion.div className="content-wrapper" initial="exit" animate="enter" exit="exit">
      <motion.div variants={fadeIn}>
      <img className="logo" alt="Neobility Logo" src={logoLandscape}/>
      <div className="content">
        <div className="title-wrapper">
          <div className="title">We’re building the future of urban mobility. <br/>Join us, we’re hiring!</div>
          <a href="mailto:jobs@neobility.co"><Button type="tertiary">Join us!</Button></a>
        </div>
        <div className="sub-title-wrapper">
          <div className="sub-title">In the meantime, check out what we’ve been working on!</div>
          <div className="buttons">
            <div className="btn-wrapper">
              <a href="https://www.rideurbanair.com" target="_blank"><Button icon={urbanAirIcon} className="urban-btn" type="primary">UrbanAir</Button></a>
              <div className="btn-label">Our shared mobility consumer app</div>
            </div>
            <div className="btn-wrapper">
              <a href="https://delivery.neobility.co" target="_blank"><Button icon={neoDeliveryIcon} type="secondary">Neo.Delivery</Button></a>
              <div className="btn-label">Our multi-point P2P delivery platform</div>
            </div>
          </div>
        </div>
      </div>
      </motion.div>
    </motion.div>
  )
}

export default IndexContent;