import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Background from "../components/Background"
import BackgroundMobile from "../components/BackgroundMobile"
import BackgroundSafari from "../components/BackgroundSafari"
import IndexContent from "../components/IndexContent"
import Footer from "../components/Footer"
import {
  isSafari,
  isFirefox,
  isIE,
  isEdge,
  isOpera,
} from "react-device-detect";

const IndexPage = () => (
  <Layout>
    <SEO title="Neobility | We’re building the future of urban mobility" />
    <IndexContent/>
    <div className="overlay"/>
    {(typeof window !== 'undefined') ? (!isSafari && !isFirefox && !isOpera && !isEdge && !isIE) ? <Background/> : "" : ""}
    <div className="fullscreen-svg-safari">
      {(typeof window !== 'undefined') ? (isSafari || isFirefox || isOpera || isEdge || isIE) ? <BackgroundSafari/> : "" : ""}
    </div>
    <BackgroundMobile/>
    <Footer/>
  </Layout>
)

export default IndexPage
